<template>
    <div  class="mx-3  py-10">
        <div v-if="loading"><spinner/></div>
        <v-row v-else>
            <v-col md="4">
                {{ $t('Meals Sale Invoice') }}
                <AutoCompleteField  outlined v-model="structure.MEALS_OUT_INVOICE"  
                     endPoint="/inventories/invoice-types/index" returnObject/>
            </v-col>
            <!-- <v-col md="4">
                {{ structure.PRODUCTS_OUT_INVOICE ? $t('Cafeteria Output Invoice') :""}}
                <AutoCompleteField :label="$t('Cafeteria Output Invoice')" outlined v-model="structure.PRODUCTS_OUT_INVOICE" returnObject
                    endPoint="/inventories/invoice-types/index" />
            </v-col> -->
            <v-col md="4">
                {{  $t('Meals return Invoice') }}
                <AutoCompleteField  outlined v-model="structure.MEALS_IN_INVOICE" returnObject
                    endPoint="/inventories/invoice-types/index" />
            </v-col>
            <v-col md="4">
                {{  $t('Restaurant Meals Folder') }}
                <AutoCompleteField  outlined v-model="structure.MEAL_GROUP_FOLDER" returnObject
                    endPoint="/inventories/folders/auto-complete" />
            </v-col>
            <!-- <v-col md="4">
                {{ structure.PRODUCTS_FOLDER ? $t('Cafeteria Material Folder') :""}}
                <AutoCompleteField :label="$t('Cafeteria Material Folder')" outlined v-model="structure.PRODUCTS_FOLDER" returnObject
                    endPoint="/inventories/folders/auto-complete" />
            </v-col> -->
            <!-- <v-col md="12" class="py-10">
                <hr />
            </v-col> -->
            <v-col md="4">
                {{  $t('Meals Manufacturing Invoice') }}
                <AutoCompleteField  outlined v-model="structure.MEALS_CREATION_INVOICE" returnObject
                    endPoint="/inventories/invoice-types/index" />
            </v-col>
            <!-- <v-col md="4">
                {{ structure.MEAL_GROUP_FOLDER ? $t('Meals Categories Folder') :""}}
                <AutoCompleteField :label="$t('Meals Categories Folder')" outlined v-model="structure.MEAL_GROUP_FOLDER" returnObject
                    endPoint="/inventories/folders/auto-complete" />
            </v-col> -->
            <v-col md="4">
                {{ $t('Limit To Lock Meal Order') }}
                <v-text-field dense :dark="$store.state.isDarkMode" v-model="structure.MEALS_CHECK" outlined type="number" 
                ></v-text-field>
            </v-col>
            <v-col md="4"></v-col>
            <v-row justify="center" class="mt-15">
                <v-col cols="2">
                    <v-btn color="green" :loading="loadingBtn" class="save-button" @click="submit">
                    {{ $t("save") }} </v-btn>
                </v-col>
                <v-col cols="3">
                    <v-btn @click="cancel" color="error" class="cancel-button">
                    {{ $t("cancel") }} </v-btn>
                </v-col>
            </v-row>
        </v-row>
    </div>
</template>
<script>
import axios from "axios";
import AutoCompleteField from "../../../components/core/AutoCompleteField.vue";
import spinner from "../../../../../components/base/spinner.vue";
export default {
    components: { AutoCompleteField, spinner },
    data: () => ({
        tableItems: [],
        loading: false,
        loadingBtn: false,
        structure:
         {
            MEALS_CREATION_INVOICE : null,
            PRODUCTS_OUT_INVOICE : null,
            MEALS_OUT_INVOICE : null,
            MEALS_CHECK : null,
            PRODUCTS_FOLDER : null,
            MEALS_FOLDER : null,
            MEAL_GROUP_FOLDER: null,
            MEALS_IN_INVOICE: null,
        },
    }),
    methods: {
        cancel() {
      this.$router.go(-1);
    },
   async submit() {
        try {
            this.loadingBtn = true;
            this.structure.MEALS_CREATION_INVOICE = this.structure.MEALS_CREATION_INVOICE?.id;
            this.structure.PRODUCTS_OUT_INVOICE   = this.structure.PRODUCTS_OUT_INVOICE?.id;
            this.structure.MEALS_OUT_INVOICE      = this.structure.MEALS_OUT_INVOICE?.id;
            this.structure.MEALS_CHECK            = this.structure.MEALS_CHECK;
            this.structure.PRODUCTS_FOLDER        = this.structure.PRODUCTS_FOLDER?.id;
            this.structure.MEALS_FOLDER           = this.structure.MEALS_FOLDER?.id;
            this.structure.MEAL_GROUP_FOLDER      = this.structure.MEAL_GROUP_FOLDER?.id;
            this.structure.MEALS_IN_INVOICE       = this.structure.MEALS_IN_INVOICE?.id;
            const res = await axios.post("/settings/meals",this.structure);
            if(res.status == 200){
                this.$Notifications(
                this.$t('Saved successfully'),
                { timeout: 1000, rtl: true },
                "success"
                );
            this.fetchData();
            }
        } catch (error) {
        }
        finally {
            this.loadingBtn = false;
        }
    },
        async fetchData() {
            try {
                this.loading = true;
                const res = await axios.get("/settings/meals");
                this.tableItems = res.data.data;
                this.structure.MEALS_CREATION_INVOICE = this.tableItems.MEALS_CREATION_INVOICE;
                this.structure.PRODUCTS_OUT_INVOICE   = this.tableItems.PRODUCTS_OUT_INVOICE;
                this.structure.MEALS_OUT_INVOICE      = this.tableItems.MEALS_OUT_INVOICE;
                this.structure.MEALS_CHECK            = this.tableItems.MEALS_CHECK;
                this.structure.PRODUCTS_FOLDER        = this.tableItems.PRODUCTS_FOLDER;
                // this.structure.MEALS_FOLDER           = this.tableItems.MEALS_FOLDER;
                this.structure.MEAL_GROUP_FOLDER      = this.tableItems.MEAL_GROUP_FOLDER;
                this.structure.MEALS_IN_INVOICE       = this.tableItems.MEALS_IN_INVOICE;
            } catch (error) {
             }
             finally {
                this.loading = false;
            }
        },
    },
    created() {
        this.fetchData();
    },
};
</script>
<style></style>