<template>
    <div  class="mx-3  py-10">
        <div v-if="loading"><spinner/></div>
        
        <v-row v-else>
            <v-col md="6">
                {{ structure.STUDENT_ACCOUNT_FOLDER ? $t('Students Folder') :""}}
                <AutoCompleteField :label="$t('Students Folder')" outlined v-model="structure.STUDENT_ACCOUNT_FOLDER" returnObject
                    endPoint="/accounting/folder/auto-complete" />
            </v-col>
            <v-col md="6">
                {{ structure.EMPLOYEE_FOLDER ? $t('Employees Folder') :""}}
                <AutoCompleteField :label="$t('Employees Folder')" outlined v-model="structure.EMPLOYEE_FOLDER" returnObject
                    endPoint="/accounting/folder/auto-complete" />
            </v-col>
            <v-col md="6">
                {{ structure.COSTUMER_FOLDER ? $t('Customers Folder') :""}}
                <AutoCompleteField :label="$t('Customers Folder')" outlined v-model="structure.COSTUMER_FOLDER" returnObject
                    endPoint="/accounting/folder/auto-complete" />
            </v-col>
            <v-col md="6">
                {{ structure.VENDOR_FOLDER ? $t('Vendors Folder') :""}}
                <AutoCompleteField :label="$t('Vendors Folder')" outlined v-model="structure.VENDOR_FOLDER" returnObject
                    endPoint="/accounting/folder/auto-complete" />
            </v-col>
            <v-col md="12" class="py-10">
                <hr />
            </v-col>
            <v-col md="4">
                {{ structure.TEACHER_ACCOUNT ? $t('Teachers Account') :""}}
                <AutoCompleteField :label="$t('Teachers Account')" outlined v-model="structure.TEACHER_ACCOUNT" returnObject
                    endPoint="/accounting/account/auto-complete" />
            </v-col>
            <v-col md="4">
                {{ structure.PARENT_ACCOUNT ? $t('Parents Account') :""}}
                <AutoCompleteField :label="$t('Parents Account')" outlined v-model="structure.PARENT_ACCOUNT" returnObject
                    endPoint="/accounting/account/auto-complete" />
            </v-col>
            <v-col md="4">
                {{ structure.STUDENT_ACCOUNT ? $t('Students Account') :""}}
                <AutoCompleteField :label="$t('Students Account')" outlined v-model="structure.STUDENT_ACCOUNT" returnObject
                    endPoint="/accounting/account/auto-complete" />
            </v-col>
            <v-col md="4">
                {{ structure.BUFFET_REVENUES_ACCOUNT ? $t('Buffet Revenue Account') :""}}
                <AutoCompleteField :label="$t('Buffet Revenue Account')" outlined v-model="structure.BUFFET_REVENUES_ACCOUNT" returnObject
                    endPoint="/accounting/account/auto-complete" />
            </v-col>
            <v-col md="4">
                {{ structure.BUFFET_COST_CENTER ? $t('Buffet Cost Center') :""}}
                <AutoCompleteField :label="$t('Buffet Cost Center')" outlined v-model="structure.BUFFET_COST_CENTER" returnObject
                    endPoint="/accounting/cost-center/auto-complete" />
            </v-col>
            <v-col md="4" class="py-8">
                <v-checkbox :dark="$store.state.isDarkMode" :label="$t('Active Account Roles')" dense v-model="structure.ACTIVATE_ACCOUNT_ROLES" 
                            :true-value="'1'" :false-value="'2'" class="mx-4 mb-4" ></v-checkbox>
            </v-col>
            <v-row justify="center" class="mt-15">
                <v-col cols="2">
                    <v-btn color="green" :loading="loadingBtn" class="save-button" @click="submit">{{
                    $t("save")
                    }}</v-btn>
                </v-col>
                <v-col cols="3">
                    <v-btn @click="cancel" color="error" class="cancel-button">{{
                    $t("cancel")
                    }}</v-btn>
                </v-col>
            </v-row>
        </v-row>
    </div>
</template>
<script>
import axios from "axios";
import AutoCompleteField from "../../../components/core/AutoCompleteField.vue";
import spinner from "../../../../../components/base/spinner.vue";
export default {
    components: { AutoCompleteField, spinner },
    data: () => ({
        tableItems: [],
        loading: false,
        loadingBtn: false,
        structure: {
            STUDENT_ACCOUNT: null,
            TEACHER_ACCOUNT: null,
            PARENT_ACCOUNT: null,
            BUFFET_REVENUES_ACCOUNT: null,
            STUDENT_ACCOUNT_FOLDER: null,
            EMPLOYEE_FOLDER: null,
            COSTUMER_FOLDER: null,
            VENDOR_FOLDER: null,
            BUFFET_COST_CENTER: null,
            ACTIVATE_ACCOUNT_ROLES: "1",
        },
    }),
    methods: {
        cancel() {
      this.$router.go(-1);
    },
   async submit() {
        try {
            this.loadingBtn = true;
            this.structure.BUFFET_COST_CENTER      = this.structure.BUFFET_COST_CENTER?.id     ;
            this.structure.BUFFET_REVENUES_ACCOUNT = this.structure.BUFFET_REVENUES_ACCOUNT?.id;
            this.structure.COSTUMER_FOLDER         = this.structure.COSTUMER_FOLDER?.id        ;
            this.structure.EMPLOYEE_FOLDER         = this.structure.EMPLOYEE_FOLDER?.id        ;
            this.structure.PARENT_ACCOUNT          = this.structure.PARENT_ACCOUNT?.id         ;
            this.structure.STUDENT_ACCOUNT         = this.structure.STUDENT_ACCOUNT?.id        ;
            this.structure.STUDENT_ACCOUNT_FOLDER  = this.structure.STUDENT_ACCOUNT_FOLDER?.id ;
            this.structure.TEACHER_ACCOUNT         = this.structure.TEACHER_ACCOUNT?.id        ;
            this.structure.VENDOR_FOLDER           = this.structure.VENDOR_FOLDER?.id          ;
            const res = await axios.post("/settings/accounting",this.structure);
            if(res.status == 200){
                this.$Notifications(
                this.$t('Saved successfully'),
                { timeout: 1000, rtl: true },
                "success"
                );
            this.fetchData();
            }
        } catch (error) {
        }
        finally {
            this.loadingBtn = false;
        }
    },
        async fetchData() {
            try {
                this.loading = true;
                const res = await axios.get("/settings/accounting");
                this.tableItems = res.data.data;
                this.structure.BUFFET_COST_CENTER = this.tableItems.BUFFET_COST_CENTER;
                this.structure.BUFFET_REVENUES_ACCOUNT = this.tableItems.BUFFET_REVENUES_ACCOUNT;
                this.structure.COSTUMER_FOLDER = this.tableItems.COSTUMER_FOLDER;
                this.structure.EMPLOYEE_FOLDER = this.tableItems.EMPLOYEE_FOLDER;
                this.structure.PARENT_ACCOUNT = this.tableItems.PARENT_ACCOUNT;
                this.structure.STUDENT_ACCOUNT = this.tableItems.STUDENT_ACCOUNT;
                this.structure.STUDENT_ACCOUNT_FOLDER = this.tableItems.STUDENT_ACCOUNT_FOLDER;
                this.structure.TEACHER_ACCOUNT = this.tableItems.TEACHER_ACCOUNT;
                this.structure.VENDOR_FOLDER = this.tableItems.VENDOR_FOLDER;
                this.structure.ACTIVATE_ACCOUNT_ROLES = this.tableItems.ACTIVATE_ACCOUNT_ROLES;
            } catch (error) {
             }
             finally {
                this.loading = false;
            }
        },
    },
    created() {
        this.fetchData();
    },
};
</script>
<style></style>