<template>
    <v-container>
      <v-row>
        <v-col md="12">
          <v-toolbar :dark="$store.state.isDarkMode">
            <template>
              <v-tabs v-model="tab" align-with-title color="#757575">
                <v-tabs-slider></v-tabs-slider>
                <v-col md="1"></v-col>
                <!-- v-if="returnAbility('delivery_note:orders')" -->
                <v-col md="3">
                  <v-tab :href="'#' + '1'" @click="tab = 1">
                    {{ $t("Accounts") }}
                  </v-tab>
                </v-col>
                <v-col md="3">
                  <v-tab :href="'#' + '2'" @click="tab = 2">
                    {{ $t("Users") }}
                  </v-tab>
                </v-col>
                <v-col md="3">
                  <v-tab :href="'#' + '3'" @click="tab = 3">
                    {{ $t("meals") }}
                  </v-tab>
                </v-col>
              </v-tabs>
            </template>
          </v-toolbar>
        </v-col>
      </v-row>
      <v-tabs-items :dark="$store.state.isDarkMode" v-model="tab">
        <v-tab-item value="1">
          <accountsSettings v-if="tab == '1'" />
        </v-tab-item>
        <v-tab-item value="2">
          <numberUser v-if="tab == '2'" />
        </v-tab-item>
        <v-tab-item value="3">
          <mealsSettings v-if="tab == '3'" />
        </v-tab-item>
      </v-tabs-items>
    </v-container>
  </template>
  
  <script>
  import accountsSettings from './accountsSettings.vue'
  import numberUser from "./numberUsers.vue";
  import mealsSettings from './mealsSettings.vue';
  export default {
    components: {
      accountsSettings,
      numberUser,
      mealsSettings,
    },
    data() {
      return {
        tab: this.$store.state.tabWorkFlow,
      };
    },
    watch: {
        tab: function (newval) {
          this.$store.commit("SET_TABWORKFLOW", newval);
        },
      },
  };
  </script>
  
  