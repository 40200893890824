<template>
    <div  class="mx-3  py-10">
        <div v-if="loading"><spinner/></div>
        
        <v-row v-else>
            <v-col md="6">
                <v-text-field :dark="$store.state.isDarkMode" :label="$t('Dashboard Users Number')" type="number" v-model="structure.USER_LIMIT">
                </v-text-field>
            </v-col>
            <v-col md="6">
                <v-text-field :dark="$store.state.isDarkMode" :label="$t('App Parent Users Number')" type="number" v-model="structure.USER_PARENT_LIMIT">
                </v-text-field>
            </v-col>
            <v-col md="6">
                <v-text-field :dark="$store.state.isDarkMode" :label="$t('App Students Users Number')" type="number" v-model="structure.USER_STUDENT_LIMIT">
                </v-text-field>
            </v-col>
            <v-col md="6">
                <v-text-field :dark="$store.state.isDarkMode" :label="$t('App Bus Users Number')" type="number" v-model="structure.USER_BUS_LIMIT">
                </v-text-field>
            </v-col>
            <v-col md="6">
                <v-text-field :dark="$store.state.isDarkMode" :label="$t('App Teachers Users Number')" type="number" v-model="structure.USER_TEACHER_LIMIT">
                </v-text-field>
            </v-col>
            <v-col class="py-0" md="6">
                <span style="font-size: x-small;">{{  $t('Teacher Term') }}</span>
                <AutoCompleteField end-point="/terms" dense v-model="structure.USER_TEACHER_TERM" returnObject/> 
            </v-col>
            <v-row justify="center" class="mt-15">
                <v-col cols="2">
                    <v-btn color="green" :loading="loadingBtn" class="save-button" @click="submit">{{
                    $t("save")
                    }}</v-btn>
                </v-col>
                <v-col cols="3">
                    <v-btn @click="cancel" color="error" class="cancel-button">{{
                    $t("cancel")
                    }}</v-btn>
                </v-col>
            </v-row>
        </v-row>
    </div>
</template>
<script>
import axios from "axios";
import AutoCompleteField from "../../../components/core/AutoCompleteField.vue";
import spinner from "../../../../../components/base/spinner.vue";
export default {
    components: { AutoCompleteField, spinner },
    data: () => ({
        tableItems: [],
        loading: false,
        loadingBtn: false,
        structure: {
            USER_LIMIT: null,
            USER_PARENT_LIMIT: null,
            USER_STUDENT_LIMIT: null,
            USER_BUS_LIMIT: null,
            USER_TEACHER_LIMIT: null,
            USER_TEACHER_TERM: null,
        },
    }),
    methods: {
        cancel() {
      this.$router.go(-1);
    },
   async submit() {
        try {
            this.structure = {
                ...this.structure,
                USER_TEACHER_TERM: this.structure.USER_TEACHER_TERM?.id,
            }
            const res = await axios.post("/settings/user",this.structure);
            if(res.status == 200){
                this.$Notifications(
                this.$t('Saved successfully'),
                { timeout: 1000, rtl: true },
                "success"
                );
            this.fetchData();
            }
        } catch (error) {
        }
        finally {
            this.loadingBtn = false;
        }
    },
        async fetchData() {
            try {
                this.loading = true;
                const res = await axios.get("/settings/user");
                this.structure = res.data.data
                // this.structure.BUFFET_COST_CENTER = this.tableItems.BUFFET_COST_CENTER;
                // this.structure.BUFFET_REVENUES_ACCOUNT = this.tableItems.BUFFET_REVENUES_ACCOUNT;
                // this.structure.COSTUMER_FOLDER = this.tableItems.COSTUMER_FOLDER;
                // this.structure.EMPLOYEE_FOLDER = this.tableItems.EMPLOYEE_FOLDER;
                // this.structure.PARENT_ACCOUNT = this.tableItems.PARENT_ACCOUNT;
            } catch (error) {
             }
             finally {
                this.loading = false;
            }
        },
    },
    created() {
        this.fetchData();
    },
};
</script>
<style></style>